let newsGrid = document.querySelector('.grid-news');

if (newsGrid) {
		let newsItems = newsGrid.querySelectorAll('.card');
		let showMore = document.querySelector('.show-more');

		let maxNewsItems = 6;

		if (newsItems.length >= maxNewsItems) {
				for (let i = 0; i < maxNewsItems; i++) {
						newsItems[i].classList.remove('hidden');
				}


				if (showMore) {
						showMore.addEventListener('click', function () {
								for (let i = 0; i < newsItems.length; i++) {
										if (newsItems[i].classList.contains('hidden')) {
												newsItems[i].classList.remove('hidden');
										}
								}
								this.style.display = "none";
						});
				}
		} else {
				for (let i = 0; i < newsItems.length; i++) {
						if (newsItems[i].classList.contains('hidden')) {
								newsItems[i].classList.remove('hidden');
						}
						if (showMore) {
							showMore.style.display = "none";
						}
				}
		}
}

