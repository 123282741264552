let umbForm = document.querySelector('.umbraco-forms-form');

if (umbForm) {
	let inputs = umbForm.querySelectorAll('input');
	let textarea = umbForm.querySelectorAll('textarea');

	for (let i = 0; i < inputs.length; i++) {
		if (inputs[i].value) {
			inputs[i].classList.add("filled");
		}
	}

for (let i = 0; i < textarea.length; i++) {
		if (textarea[i].value !== '') {
				textarea[i].classList.add("filled");
		}
	}
}
