'use strict'

const accordionSearch = {
		init: function () {

				let containers = document.querySelectorAll("[data-accordion='container']");
				for (let i = 0; i < containers.length; i++) {

						// save the container
						let container = containers[i];

						// save the different parts
						let searchForm = container.querySelector("[data-accordion='search']");
						let searchField = container.querySelector("[data-accordion='search-field']");
						let accordionItems = container.querySelectorAll("[data-accordion='accordion-item']");

						if (searchField) {
								// a search field is required in order to be able to search.

								// add eventlisteners for keyup and submit, and perform a search initially,
								// in case the search field is filled on load
								searchField.addEventListener("mouseup", () => {
										if (searchField.value != "") {
												setTimeout(function () {
														if (searchField.value == "") {
																for (let i = 0; i < accordionItems.length; i++) {
																		accordionItems[i].open = false;
																		accordionItems[i].removeAttribute("open"); // necessary for browsers not supporting <details>
																		accordionItems[i].classList.remove("hidden");
																}
														}
												}, 100);
										}
								});

								searchField.addEventListener("keyup", () => {
										this.performSearch(accordionItems, searchField.value);
								});

								if (searchForm) {
										searchForm.addEventListener("submit", () => {
												this.performSearch(accordionItems, searchField.value);
										});
								}

								this.performSearch(accordionItems, searchField.value);
						}

				}
		},

		performSearch: function (accordionItems, searchTerm) {
				if (!searchTerm || searchTerm === "") {
						// searchTerm is either null or empty, close all
						for (let i = 0; i < accordionItems.length; i++) {
								accordionItems[i].open = false;
								accordionItems[i].removeAttribute("open"); // necessary for browsers not supporting <details>
								accordionItems[i].classList.remove("hidden");
						}
				}
				else {
						// find accordion items containing searchTerm and open
						for (let i = 0; i < accordionItems.length; i++) {
								if (accordionItems[i].textContent.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
										accordionItems[i].open = true;
										accordionItems[i].setAttribute("open", "open"); // necessary for browsers not supporting <details>
										accordionItems[i].classList.remove("hidden");
								}
								else {
										accordionItems[i].open = false;
										accordionItems[i].removeAttribute("open"); // necessary for browsers not supporting <details>
										accordionItems[i].classList.add("hidden");
								}
						}
				}
		}
}

export default accordionSearch.init();
