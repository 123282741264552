const body = document.querySelector('body');
const header = document.querySelector('header');
const dropdownToggler = document.querySelectorAll('[data-toggle-nav]');
const dropdownNav = document.querySelectorAll('[data-nav-id]');

for (let i = 0; i < dropdownToggler.length; i++) {

    dropdownToggler[i].addEventListener('click', function (e) {
        let navID = dropdownToggler[i].dataset.toggleNav;
        let subNav = e.target.closest('.mobile-navigation-dropdown');

        if ( subNav == null) {
            for (let p = 0; p < dropdownNav.length; p++) {
                let nav = dropdownNav[p];

                if (navID == nav.dataset.navId) {
                    let parent = this.parentElement;
										let navArrow = parent.querySelector('.nav-arrow');
										nav.classList.toggle('is-active');
                    nav.parentElement.classList.toggle('toggle_active');

                    if (navArrow) {
                        navArrow.classList.toggle('open');
                    }
                }
            }
        }
    })
}


