import zenscroll from "zenscroll"

'use strict'

const smoothScroll = {
		init: function () {
				var defaultDuration = 750 //ms
				var edgeOffset = 0;

				zenscroll.setup(defaultDuration, edgeOffset);
		},
}

export default smoothScroll.init()
