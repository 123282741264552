//import enablePreviousForm from './formShowPrevious';
import validateOnClick from './formEnableButton';

let nextBtns = document.querySelectorAll('a.show-next');
let formSections = document.querySelectorAll('[data-form-id]');

for (let i = 0; i < nextBtns.length; i++) {
		let btn = nextBtns[i];

		btn.addEventListener('click', function () {

				let formSectionID = this.dataset.formSection;
				let parent = this.closest('.form-section');
				let header = parent.querySelector('.form-header');
				let headerNumber = header.querySelector('.rounded-box');
				let form = parent.querySelector('.form-inputs');

				if (this.dataset.validatePartial === 'true') {
						if (!$(form).validate().checkForm()) {
								return false;
						} else {
								var errors = $('[data-id=Documentportalerrors]')[0];
								if (errors) {
										errors.remove();
								}
						}
				}

				header.classList.add('valid')
				headerNumber.classList.add('valid');
				form.classList.add('hidden');

				for (let f = 0; f < formSections.length; f++) {
						let formSection = formSections[f];
						let formID = formSection.dataset.formId;

						if (formSectionID == formID) {
								let parent = formSection.closest('.form-section');
								let header = parent.querySelector('span');

								formSection.classList.remove('hidden');
								formSection.classList.remove('inactive');
								header.classList.add('active');
						}
				}

				//enablePreviousForm()				
		})
}
