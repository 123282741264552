const modal = {
		init: function () {
				var modals = document.querySelectorAll('[data-modal]')
				for (var i = 0; i < modals.length; i++) {
						modals[i].addEventListener('click', function (event) {
								if (event.target.closest("button") != null) {
										var target = event.target.closest("button").getAttribute("data-modal")
								}
								event.preventDefault()
								toggleModal(target)
						})
				}

				var overlay = document.querySelectorAll('[data-modal-overlay]')
				for (var i = 0; i < overlay.length; i++) {
						overlay[i].addEventListener('click', toggleModal)
				}

				var closemodal = document.querySelectorAll('[data-modal-close]')
				for (var i = 0; i < closemodal.length; i++) {
						closemodal[i].addEventListener('click', toggleModal)
				}

				document.addEventListener('keydown', function (evt) {
						evt = evt || window.event
						var isEscape = false
						if ("key" in evt) {
								isEscape = (evt.key === "Escape" || evt.key === "Esc")
						} else {
								isEscape = (evt.keyCode === 27)
						}
						if (isEscape && document.body.classList.contains('body--lock')) {
								toggleModal()
						}
				})

				function toggleModal(key) {
						const body = document.querySelector('body')
						body.classList.toggle('modal--active')
						var modal = document.querySelector('[data-modal-content="' + key + '"]')
						if (modal == null) {
								modal = document.querySelector("[data-modal-active]")
								modal.removeAttribute("data-modal-active")
						}
						else {
								modal.setAttribute("data-modal-active", "")
						}
						modal.classList.toggle('opacity-0')
						modal.classList.toggle('pointer-events-none')
						body.classList.toggle('body--lock')
				}
		}
}

export default modal.init()
