const header = document.querySelector('header');
if (header) {
		const dropdownTogglerMobile = header.querySelectorAll('[data-toggle-mobile-nav]');
		if (dropdownTogglerMobile.length) {
				let mobileNavContainer = document.querySelector('.mobile-navigation-container');
				let dropdownNav = mobileNavContainer.querySelectorAll('[data-mobile-nav-id]');

				for (let i = 0; i < dropdownTogglerMobile.length; i++) {

						dropdownTogglerMobile[i].addEventListener('click', function (e) {
								let t = e.target;

								for (let p = 0; p < dropdownNav.length; p++) {

										let navSub = dropdownNav[p];
										let navSubID = navSub.dataset.mobileNavId;
										let navID = parseInt(dropdownTogglerMobile[i].dataset.toggleMobileNav);

										if (navID == navSubID) {

												let parent = this.parentElement;
												let navArrow = parent.querySelector('.nav-arrow');

												if (navSub.classList.contains('is-active')) {

														let activeSub = navSub.querySelectorAll('.is-active');

														if (navArrow) {
																navArrow.classList.remove('open');
														}

														for (let i = 0; i < activeSub.length; i++) {
																if (activeSub[i].classList.contains('is-active')) {
																		activeSub[i].classList.remove('is-active');
																}
														}

														navSub.classList.remove('is-active')

												} else {
														if (navArrow) {
																navArrow.classList.add('open');
														}
														navSub.classList.add('is-active');
														navSub.parentElement.classList.add('toggle_active');
														if (navSub.firstElementChild.classList.contains('subnav')) {
																navSub.firstElementChild.classList.add('is-active')
														}
												}

												e.stopPropagation()
										}
								}
						});
				}
		}
}
