import {
    tns
} from "../../node_modules/tiny-slider/src/tiny-slider";

let container = document.querySelector('.testimonials.multiple');

if (container) {
    let slider = tns({
        container: container,
        items: 1,
        autoHeight: false,
        center: true,
        controlsContainer: '.slider-controls',
        nav: false,
        loop: false
    })
}
