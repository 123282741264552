const body = document.querySelector('body');
const header = document.querySelector('header');
const dropdownToggler = document.querySelectorAll('[data-toggle-nav]');
let topEl = document.querySelectorAll('.top-el');



for (let t = 0; t < topEl.length; t++) {
		topEl[t].addEventListener('click', function (e) {
				let parent = document.querySelector('.nav-list');
				let activeTop = parent.querySelectorAll('.top-el');

				topEl[t].classList.toggle('is-active');

				for (let o = 0; o < activeTop.length; o++) {
						if (activeTop[o].classList.contains('is-active')) {
								activeTop[o].querySelector('.navigation-dropdown').classList.remove('is-active')
						}
				}
		})
}

for (let i = 0; i < dropdownToggler.length; i++) {
		dropdownToggler[i].addEventListener('click', function (e) {

				// Inject target url in child nav

				let targetParent = e.target.parentElement;
				let targetEl = targetParent.querySelector('[data-sub-url]');

				if (!targetParent.classList.contains('top-el')) {
						let targetUrl = targetEl.dataset.subUrl;
						let targetText = targetEl.innerText;
						let targetDestination = targetParent.querySelector('ul.subnav');
						let x = targetDestination.querySelector('.target');

						if (!x) {
								if (targetUrl) {
										let li = document.createElement('LI');
										let anchor = document.createElement('a');

										li.className = 'target inline-flex min-w-nav-item items-center px-8 cursor-pointer border-t border-grey-290 py-8 mt-8'

										anchor.href = targetUrl;
										anchor.innerText = targetText;

										li.appendChild(anchor);

										targetDestination.appendChild(li);
								}
						}

				}

				if (dropdownToggler[i].classList.contains('has-children')) {
						let navID = parseInt(dropdownToggler[i].dataset.toggleNav);
						let dropdownNav = dropdownToggler[i].querySelector('[data-nav-id]');
						let subNavId = parseInt(dropdownNav.dataset.navId);
						let parentList = dropdownToggler[i].closest('.navigation-dropdown');
						let target = e.target.closest('li');
						let topItem = target.parentElement.parentElement;

						if (parentList) {
								let topParent = parentList.querySelector('.top-parent');
								let activeChildrenAll = parentList.querySelectorAll(' ul > li.is-active');
								let topParentList = parentList.querySelectorAll('.top-parent');

								if (topItem == parentList) {
										if (!topParent) {
												if (!target.parentElement.parentElement.classList.contains('top-parent')) {
														target.classList.add('top-parent')
												}
										} else {
												if (target.parentElement.parentElement.classList.contains('top-parent')) {
														return;
												}
										}
								}

								if (topParentList.length) {
										for (let i = 0; i < topParentList.length; i++) {
												let closestParent = target.closest('.top-parent');

												if (!closestParent) {
														if (topParentList[i] != target) {
																let activeChildren = topParentList[i].querySelectorAll('.is-active');

																topParentList[i].classList.remove('top-parent');
																topParentList[i].classList.remove('is-active');

																for (let c = 0; c < activeChildren.length; c++) {
																		activeChildren[c].classList.remove('is-active');
																}

																target.classList.add('top-parent');
																target.classList.add('is-active');
														}
												}
										}
								}

								for (let i = 0; i < activeChildrenAll.length; i++) {
										let activeSubNavs = activeChildrenAll[i].querySelectorAll('.is-active');
										let topNav = activeChildrenAll[i].closest('.top-parent');
										let topNavChildren = target.parentElement.querySelectorAll('.is-active');

										if (!target.classList.contains('is-active')) {
												for (let c = 0; c < topNavChildren.length; c++) {

														if (target != topNavChildren[c]) {
																topNavChildren[c].classList.remove('is-active');
														}
												}
										}
								}
						}

						if (navID === subNavId) {
								let t = e.target;

								if (!t.matches('li')) {
										t = e.target.closest('li');
								}

								if (dropdownNav.matches('.is-active')) {
										let activeSub = t.querySelectorAll('.is-active');

										for (let i = 0; i < activeSub.length; i++) {
												if (activeSub[i].classList.contains('is-active')) {
														activeSub[i].classList.remove('is-active');
												}
										}

								} else {
										t.classList.add('is-active');
										dropdownNav.classList.add('is-active');
										if (dropdownNav.firstElementChild.classList.contains('subnav')) {
												dropdownNav.firstElementChild.classList.add('is-active')
										}
								}

								e.stopPropagation()
						}
				}
		})
}
