import checkIfValid from './formCheckIfValid';

let forms = document.querySelectorAll('form');

for (let i = 0; i < forms.length; i++) {

		let inputs = forms[i].querySelectorAll('input');

		for (let f = 0; f < inputs.length; f++) {
				if (inputs[f].type == "radio") {
						inputs[f].addEventListener("change", function () {
								//$(this).valid();
								//checkIfValid(this)
						})
				} else {
						inputs[f].addEventListener("blur", function () {
								//if (this.value.length) {
								//		return;
								//}

								//$(this).valid()
								//checkIfValid(this)

						})
				}
		}
}


