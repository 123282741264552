import objectFitImages from "object-fit-images";
import objectFitVideos from "object-fit-videos";

objectFitImages();
objectFitVideos();

import "picturefill";
import lazysizes from "lazysizes";

import "details-element-polyfill";

import "./components/hamburger.js";
import "./components/main-nav.js";
import "./components/mobile-nav.js";
import "./components/tinyslider.js";
import "./components/details.js";
import "./components/inputs.js";
import "./components/umbForms.js";
import "./components/formShowNext.js";
import "./components/formEnableButton.js";
import "./components/formShowHideFields";
import "./components/news.js";
import "./components/player.js";
import "./components/validation.js";
import "./components/modal.js";
import "./components/zenscroll";
import "./components/documentPortal";
import "./components/accordion-search";
import "./components/savings";
import "./components/nav-toggler";
