let form = document.querySelector('form');
import zenscroll from "zenscroll"

'use strict'

if (form) {
	console.log('james')
		// Checkbox Validation
		jQuery.validator.addMethod("checkboxChecked", function (value, element) {
				let checked = false;
				checked = $(element).is(':checked');

				return checked;
		}, '');

		//Add our custom adapter
		$.validator.unobtrusive.adapters.addBool("mustbetrue", "required");

		$.validator.unobtrusive.adapters.add('filetype', ['validtypes'], function (options) {
				options.rules['filetype'] = { validtypes: options.params.validtypes.split(',') };
				options.messages['filetype'] = options.message;
		});

		$.validator.addMethod("filetype", function (value, element, param) {
				if (!value) {
						return true;
				}

				var extension = getFileExtension(value);
				return $.inArray(extension, param.validtypes) !== -1;
		});

		$.validator.unobtrusive.adapters.add('filesize', ['validsize'], function (options) {
				options.rules['filesize'] = { validsize: options.params.validsize };
				options.messages['filesize'] = options.message;
		});

		$.validator.addMethod("filesize", function (value, element, param) {
				if (!value) {
						return true;
				}

				var actualSize = element.files[0].size;
				var allowedSize = parseInt(param.validsize);
				if (actualSize && allowedSize) {
						return actualSize <= allowedSize;
				}
				return false;
		});

		$.validator.setDefaults({
				ignore: function (index, el) {
						var $el = $(el);
						var $elName = $el.prop("name");

						if ($elName === 'SelectedProductCode') {
								return false;
						}

						// Default behavior
						return $el.is(':hidden');
				}
		});


		// After submit - check if success or fail
		window.savingsSuccess = function (data) {

				var errors = $('[data-id=Savingserrors]')[0];
				if (errors) {
						errors.remove();
				}

				if (data.Redirect) {
						window.location.href = data.Redirect;
				}
				$('[data-id=' + data.NextButton + ']')[0].click();
		};

		window.savingsFailure = function (data) {
				console.log(data.responseText);
				alert(data.responseJSON.customerFacingMessage);
		};

		window.setProductCode = function (product) {
				let form = product.closest('form');
				let selectedProduct = form.querySelector('a[data-product-selected="true"]');
				let hiddenProductCode = form.querySelector('#SelectedProductCode');

				if (selectedProduct) {
						selectedProduct.classList.remove('product-accented');
						selectedProduct.setAttribute('data-product-selected', false);
				}

				product.classList.add('product-accented');
				product.setAttribute('data-product-selected', true);
				hiddenProductCode.value = product.getAttribute('data-productcode');

				$(form).valid();
		};

		$('input[data="update-file-size"]').bind('change', function () {
				if (this.files[0] && this.files[0].size) {
					const wrapper = $(this).closest($("[data-file-upload]"))
					wrapper.find('[validation-message]').removeClass('hidden')
					var totalSize = formatFileSize(this.files[0].size, 2)
					const fileTitlePlaceholder = wrapper.find($("[data-file-title-placeholder]"))
					const fileTitle = wrapper.find($("[data-file-title]"))
					const fileSize = wrapper.find($("[data-file-size]"))
					const description = wrapper.find($("[data-file-description]"))
					description.text(fileTitlePlaceholder.text())
					fileTitle.text(this.files[0].name)
					fileSize.text(totalSize)
					let form = this.closest('form')

						if ($('input[data="update-file-size"]').valid()) {
						wrapper.attr("data-file-upload", "validated")

						const fileUpload = wrapper.find($("[data-file-select]"))
						fileUpload.attr("data-file-select", "disabled")

						const container = wrapper.closest($("[data-document-portal]"))
						container.attr("data-document-portal", "")

						$(form).find("[data-document-portal-submit]").attr("data-document-portal-submit", "")
					} else {
						const container = wrapper.closest($("[data-document-portal]"))
						const availableInputFields = container.find($("input"))
						$(availableInputFields).each(function() {
							if (!$(this).hasClass("valid") && $(this).val()) {
								$(this).closest("[data-file-upload]").attr("data-file-upload", "failed")
							} else if ($(this).val()) {
								$(this).closest("[data-file-upload]").attr("data-file-upload", "validated")
								const fileUpload = $(this).closest("[data-file-upload]").find($("[data-file-select]"))
								fileUpload.attr("data-file-select", "disabled")
							}
						})

						$(form).find("[data-document-portal-submit]").attr("data-document-portal-submit", "disabled")
						container.attr("data-document-portal", "invalid")
					}
				} else {
					resetField($(this).closest($("[data-file-upload]")))
				}
		});

		$('[data-file-delete-file]').click(function () {
				resetField($(this).closest($("[data-file-upload]")))
		})

		$('input[name="DanishCitizen"]').bind('change', function () {
				if (this.value) {
						resetField($("#ResidencePermitFileFront").closest("[data-file-upload]"))
						resetField($("#ResidencePermitFileBack").closest("[data-file-upload]"))
				}
		})

		//resets all file fields onload
		$("[data='update-file-size']").each(function () {
				$(this).replaceWith($(this).val('').clone(true));
		})
}

function resetField(target) {
		const wrapper = target.closest($("[data-file-upload]"))
		const fileSelect = wrapper.find($("[data-file-select]"))
		wrapper.attr("data-file-upload", "")
		fileSelect.attr("data-file-select", "")

		const currentInput = wrapper.find($("[data='update-file-size']"))

		//reset file value
		$(currentInput).replaceWith($(currentInput).val('').clone(true));
		const fileTitle = wrapper.find($("[data-file-title]"))
		const description = wrapper.find($("[data-file-description]"))
		const fileSize = wrapper.find($("[data-file-size]"))
		fileTitle.text('')
		description.text('')
		fileSize.text('')

		target.parent().find('[validation-message]').addClass('hidden')
		let form = target.closest('form')
		if ($(form).valid()) {
			$(form).find("[data-document-portal-submit]").attr("data-document-portal-submit", "")
		} else {
			$(form).find("[data-document-portal-submit]").attr("data-document-portal-submit", "disabled")
		}
}

function formatFileSize(bytes, decimalPoint) {
		if (bytes == 0) return '0 Bytes';
		var k = 1000,
				dm = decimalPoint || 2,
				sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
				i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getFileExtension(fileName) {
		if (/[.]/.exec(fileName)) {
				return /[^.]+$/.exec(fileName)[0].toLowerCase();
		}
		return null;
}
