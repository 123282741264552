let citizenship = document.querySelectorAll('input[name=CitizenshipIsForeign]');
let taxCountry = document.querySelectorAll('input[name=TaxCountryIsForeign]');
let purpose = document.querySelectorAll('input[name=PurposeOfAccountIsOther]');
let danishCitizen = document.querySelectorAll('input[name=DanishCitizen]');

showHideFields(citizenship)
showHideFields(taxCountry)
showHideFields(purpose)

hideShowFields(danishCitizen)

function showHideFields(elm) {
		for (let i = 0; i < elm.length; i++) {
				elm[i].addEventListener('change', function () {
						let parent = this.closest('.input-group');
						let hiddenSibling = parent.nextElementSibling;

						if (this.value == 'True') {
								if (hiddenSibling.classList.contains('hidden')) {
										hiddenSibling.classList.remove('hidden')
								}
						} else {
								hiddenSibling.classList.add('hidden')
						}
				});
		}
}

function hideShowFields(elm) {
		for (let i = 0; i < elm.length; i++) {
				if (elm[i].value == "False" && elm[i].checked) {
						let parent = elm[i].closest('.input-group');
						let hiddenSibling = parent.nextElementSibling;

						if (hiddenSibling.classList.contains('hidden')) {
								hiddenSibling.classList.remove('hidden')
						}
				}
				elm[i].addEventListener('change', function () {
						let parent = this.closest('.input-group');
						let hiddenSibling = parent.nextElementSibling;

						if (this.value == 'False') {
								if (hiddenSibling.classList.contains('hidden')) {
										hiddenSibling.classList.remove('hidden')
								}
						} else {
								hiddenSibling.classList.add('hidden')
						}
				});
		}
}

