let documentPortal = document.querySelector('[data-document-portal-wrapper]');

'use strict'

if (documentPortal) {
		$("[data-document-portal-submit]").click(function (e) {
				if ($(this).attr("data-document-portal-submit") == "disabled") {
						e.preventDefault()
				}
				else {
						$('[data-document-portal-container]').attr("data-document-portal-container", "loading")
						$('[data-document-portal-submit]').attr("data-document-portal-submit", "loading")
				}
		})

		$("[data-document-portal-goto]").click(function () {
				const goto = $(this).attr("data-document-portal-goto")
				const formId = $("[data-form-id='" + goto + "']")
				const formSection = formId.closest($(".form-section"))
				const formHeader = formSection.find($(".form-header"))
				$(this).closest($(".form-section")).addClass("inactive")
				formId.removeClass("hidden")
				formHeader.removeClass("valid")
				formHeader.find($(".form-step")).removeClass("valid")
				$(".form-inputs").not(formId).addClass("hidden")

				//Link backgrounds
				$(".form-link--section").not(formId).removeClass("active-section");
				$(this).closest($(".form-link--section")).addClass("active-section")

				//active section
				$(".form-link--section").not(formId).parent().removeClass("active-parent");
				$(this).closest($(".form-link--section")).parent().addClass("active-parent")

				formSection.closest("form").siblings("form").addClass("hidden")
				formSection.closest("form").removeClass("hidden")

				$('html, body').animate({
						scrollTop: $("[data-document-portal]:visible").offset().top - 25
				}, 750)
		})		

		const errorMessage = documentPortal.getAttribute("data-session-timeout-message")
		setTimeout(function () {
				alert(errorMessage)
				window.location.reload()
		}, 2700000);

		$("[data-document-portal='invalid'] [data-form-section]").click(function (e) {
				e.preventDefault()
		})

		$("[data-document-portal] [data-form-section]").click(function () {
				let nextStep = $(this).attr("data-form-section")
				$(".form-section." + nextStep).removeClass("inactive")				
		})
}
