import fsLightbox from "../../node_modules/fslightbox/fslightbox.js";

var videoButton = document.querySelector(".icon-pulse");

if (videoButton) {
videoButton.addEventListener('click', function () {
		setTimeout(() => {
				let vid = document.querySelector('.fslightbox-source');
				

				vid.play()
		}, 200);
		
		})
}


//var videoPlay = document.querySelector(".video-play");
//var videoButton = document.querySelector(".icon-pulse");

//videoButton.addEventListener('click', function () {
//		if (videoPlay.paused) {
//				videoPlay.play();
//				video.muted = false;
//		} else {
//				videoPlay.pause();
//				video.muted = true;
//		}
//})

