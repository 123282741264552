let savings = document.querySelector('[data-savings-wrapper]');

'use strict'

if (savings) {
    const errorMessage = savings.getAttribute("data-session-timeout-message")
    setTimeout(function () {
        alert(errorMessage)
        window.location.reload()
    }, 2700000);

    $("#MoneyOrigin").change(function (e) {
        if (e.target.value === "5") {
            $("#MoneyOriginOtherTextarea").removeClass("hidden");
        } else {
            $("#MoneyOriginOtherTextarea").addClass("hidden");
        }
    });
}
