const header = document.querySelector('header');
const hamburger = document.querySelectorAll('.hamburger');

for (let i = 0; i < hamburger.length; i++) {
    hamburger[i].addEventListener('click', function () {       

        // if(this.classList.contains('is-active')){
        //     let isActive = header.querySelectorAll('.is-active');

        //     for(let i = 0; i < isActive.length; i++){
        //         console.log(isActive[i])
        //         isActive[i].classList.remove('is-active');
        //     }
        // }

        header.classList.toggle('is-active');
        this.classList.toggle('is-active');
    })
}